@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/components/list" as *;

jbd-app-offer-page section.description {
  ul {
    @extend .simple-list;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  strong {
    font-weight: var.$font-weight-semibold;
  }
}
