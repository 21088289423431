@use "core/src/stylesheets/base/colors" as color;

jbd-ui-button-secondary button.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: transparent;
  --mdc-outlined-button-label-text-color: #{map-get(color.$monochrome, 900)} !important;

  &::before {
    border-color: map-get(color.$accent, 300) !important;
  }
}

jbd-ui-button-tertiary button.mat-mdc-unelevated-button {
  --mdc-filled-button-label-text-color: #{map-get(color.$accent, 800)} !important;
}

// disabled styles

jbd-ui-button-secondary .mat-mdc-outlined-button.mat-primary[disabled] {
  --mdc-outlined-button-disabled-outline-color: var(
    --mdc-outlined-button-outline-color
  );
  --mdc-outlined-button-disabled-label-text-color: var(
    --mdc-outlined-button-label-text-color
  );
}

// spinner styles

jbd-ui-button-primary
  jbd-ui-button-inner
  mat-progress-spinner.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(
    --mdc-filled-button-label-text-color
  );
}

jbd-ui-button-secondary
  jbd-ui-button-inner
  mat-progress-spinner.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(
    --mdc-outlined-button-label-text-color
  );
}
